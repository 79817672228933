export const CalculatorVpcCalculator = () => import('../../components/Forms/Calculator/VpcCalculator.vue' /* webpackChunkName: "components/calculator-vpc-calculator" */).then(c => wrapFunctional(c.default || c))
export const CalculatorVpsCalculator = () => import('../../components/Forms/Calculator/VpsCalculator.vue' /* webpackChunkName: "components/calculator-vps-calculator" */).then(c => wrapFunctional(c.default || c))
export const Linux = () => import('../../components/Forms/linux/index.vue' /* webpackChunkName: "components/linux" */).then(c => wrapFunctional(c.default || c))
export const ContactForm = () => import('../../components/Forms/ContactForm/Index.vue' /* webpackChunkName: "components/contact-form" */).then(c => wrapFunctional(c.default || c))
export const ContactFormModal = () => import('../../components/Forms/ContactForm/Modal.vue' /* webpackChunkName: "components/contact-form-modal" */).then(c => wrapFunctional(c.default || c))
export const Windows = () => import('../../components/Forms/windows/index.vue' /* webpackChunkName: "components/windows" */).then(c => wrapFunctional(c.default || c))
export const Breadcrumb = () => import('../../components/partials/Breadcrumb.vue' /* webpackChunkName: "components/breadcrumb" */).then(c => wrapFunctional(c.default || c))
export const BreadcrumbN = () => import('../../components/partials/BreadcrumbN.vue' /* webpackChunkName: "components/breadcrumb-n" */).then(c => wrapFunctional(c.default || c))
export const Footer = () => import('../../components/partials/Footer.vue' /* webpackChunkName: "components/footer" */).then(c => wrapFunctional(c.default || c))
export const HeaderDesktop = () => import('../../components/partials/HeaderDesktop.vue' /* webpackChunkName: "components/header-desktop" */).then(c => wrapFunctional(c.default || c))
export const HeaderLinks = () => import('../../components/partials/HeaderLinks.vue' /* webpackChunkName: "components/header-links" */).then(c => wrapFunctional(c.default || c))
export const HeaderMobile = () => import('../../components/partials/HeaderMobile.vue' /* webpackChunkName: "components/header-mobile" */).then(c => wrapFunctional(c.default || c))
export const PageHeader = () => import('../../components/partials/PageHeader.vue' /* webpackChunkName: "components/page-header" */).then(c => wrapFunctional(c.default || c))
export const PageHeaderN = () => import('../../components/partials/PageHeaderN.vue' /* webpackChunkName: "components/page-header-n" */).then(c => wrapFunctional(c.default || c))
export const RightDrawer = () => import('../../components/partials/RightDrawer.vue' /* webpackChunkName: "components/right-drawer" */).then(c => wrapFunctional(c.default || c))
export const Tooltip = () => import('../../components/partials/Tooltip.vue' /* webpackChunkName: "components/tooltip" */).then(c => wrapFunctional(c.default || c))
export const HeaderTheDrawer = () => import('../../components/partials/Header/TheDrawer.vue' /* webpackChunkName: "components/header-the-drawer" */).then(c => wrapFunctional(c.default || c))
export const HeaderTheHeader = () => import('../../components/partials/Header/TheHeader.vue' /* webpackChunkName: "components/header-the-header" */).then(c => wrapFunctional(c.default || c))
export const HeaderTheHeaderLinks = () => import('../../components/partials/Header/TheHeaderLinks.vue' /* webpackChunkName: "components/header-the-header-links" */).then(c => wrapFunctional(c.default || c))
export const HeaderTheHeaderTop = () => import('../../components/partials/Header/TheHeaderTop.vue' /* webpackChunkName: "components/header-the-header-top" */).then(c => wrapFunctional(c.default || c))
export const HeaderTheMenu = () => import('../../components/partials/Header/TheMenu.vue' /* webpackChunkName: "components/header-the-menu" */).then(c => wrapFunctional(c.default || c))
export const HeaderTheMenuItem = () => import('../../components/partials/Header/TheMenuItem.vue' /* webpackChunkName: "components/header-the-menu-item" */).then(c => wrapFunctional(c.default || c))
export const HeaderTheMenuLeftSection = () => import('../../components/partials/Header/TheMenuLeftSection.vue' /* webpackChunkName: "components/header-the-menu-left-section" */).then(c => wrapFunctional(c.default || c))
export const HeaderTheMenuRightItem = () => import('../../components/partials/Header/TheMenuRightItem.vue' /* webpackChunkName: "components/header-the-menu-right-item" */).then(c => wrapFunctional(c.default || c))
export const HeaderTheSeoHiddenNavbar = () => import('../../components/partials/Header/TheSeoHiddenNavbar.vue' /* webpackChunkName: "components/header-the-seo-hidden-navbar" */).then(c => wrapFunctional(c.default || c))
export const BlogSection = () => import('../../components/BlogSection.vue' /* webpackChunkName: "components/blog-section" */).then(c => wrapFunctional(c.default || c))
export const Calculator = () => import('../../components/Calculator.vue' /* webpackChunkName: "components/calculator" */).then(c => wrapFunctional(c.default || c))
export const CuButton = () => import('../../components/CuButton.vue' /* webpackChunkName: "components/cu-button" */).then(c => wrapFunctional(c.default || c))
export const CuInput = () => import('../../components/CuInput.vue' /* webpackChunkName: "components/cu-input" */).then(c => wrapFunctional(c.default || c))
export const Slider = () => import('../../components/Slider.vue' /* webpackChunkName: "components/slider" */).then(c => wrapFunctional(c.default || c))
export const Acordian = () => import('../../components/acordian.vue' /* webpackChunkName: "components/acordian" */).then(c => wrapFunctional(c.default || c))
export const CardMain = () => import('../../components/cardMain.vue' /* webpackChunkName: "components/card-main" */).then(c => wrapFunctional(c.default || c))
export const DialogManageService = () => import('../../components/Dialog/ManageService.vue' /* webpackChunkName: "components/dialog-manage-service" */).then(c => wrapFunctional(c.default || c))
export const DialogLogin = () => import('../../components/Dialog/login.vue' /* webpackChunkName: "components/dialog-login" */).then(c => wrapFunctional(c.default || c))
export const CdnPlansDesktop = () => import('../../components/cdn/plans-desktop.vue' /* webpackChunkName: "components/cdn-plans-desktop" */).then(c => wrapFunctional(c.default || c))
export const CdnPlansMobile = () => import('../../components/cdn/plans-mobile.vue' /* webpackChunkName: "components/cdn-plans-mobile" */).then(c => wrapFunctional(c.default || c))
export const CdnPlans = () => import('../../components/cdn/plans.vue' /* webpackChunkName: "components/cdn-plans" */).then(c => wrapFunctional(c.default || c))
export const CustomersCategories = () => import('../../components/customers/categories.vue' /* webpackChunkName: "components/customers-categories" */).then(c => wrapFunctional(c.default || c))
export const Customers = () => import('../../components/customers/index.vue' /* webpackChunkName: "components/customers" */).then(c => wrapFunctional(c.default || c))
export const CustomersSlider = () => import('../../components/customers/slider.vue' /* webpackChunkName: "components/customers-slider" */).then(c => wrapFunctional(c.default || c))
export const EnterpriseSolutionCustomersEnterprise = () => import('../../components/enterprise-solution/CustomersEnterprise.vue' /* webpackChunkName: "components/enterprise-solution-customers-enterprise" */).then(c => wrapFunctional(c.default || c))
export const GeneralSolutions = () => import('../../components/general-solutions/index.vue' /* webpackChunkName: "components/general-solutions" */).then(c => wrapFunctional(c.default || c))
export const IranVpsDifferences = () => import('../../components/iran-vps/Differences.vue' /* webpackChunkName: "components/iran-vps-differences" */).then(c => wrapFunctional(c.default || c))
export const IranVpsFeatures = () => import('../../components/iran-vps/Features.vue' /* webpackChunkName: "components/iran-vps-features" */).then(c => wrapFunctional(c.default || c))
export const IsolatedCloudNorontaUses = () => import('../../components/isolated-cloud/norontaUses.vue' /* webpackChunkName: "components/isolated-cloud-noronta-uses" */).then(c => wrapFunctional(c.default || c))
export const IsolatedCloudSupportUses = () => import('../../components/isolated-cloud/supportUses.vue' /* webpackChunkName: "components/isolated-cloud-support-uses" */).then(c => wrapFunctional(c.default || c))
export const ManagedServicesBenefits = () => import('../../components/managedServices/Benefits.vue' /* webpackChunkName: "components/managed-services-benefits" */).then(c => wrapFunctional(c.default || c))
export const ManagedServicesCustomers = () => import('../../components/managedServices/customers.vue' /* webpackChunkName: "components/managed-services-customers" */).then(c => wrapFunctional(c.default || c))
export const ManagedServicesIssues = () => import('../../components/managedServices/issues.vue' /* webpackChunkName: "components/managed-services-issues" */).then(c => wrapFunctional(c.default || c))
export const ManagedServicesLevelsServices = () => import('../../components/managedServices/levelsServices.vue' /* webpackChunkName: "components/managed-services-levels-services" */).then(c => wrapFunctional(c.default || c))
export const ManagedServicesOs = () => import('../../components/managedServices/os.vue' /* webpackChunkName: "components/managed-services-os" */).then(c => wrapFunctional(c.default || c))
export const ManagedServicesPossibility = () => import('../../components/managedServices/possibility.vue' /* webpackChunkName: "components/managed-services-possibility" */).then(c => wrapFunctional(c.default || c))
export const OrganizationalSolutionCustomersEnterprise = () => import('../../components/organizationalSolution/CustomersEnterprise.vue' /* webpackChunkName: "components/organizational-solution-customers-enterprise" */).then(c => wrapFunctional(c.default || c))
export const OrganizationalSolutionUses = () => import('../../components/organizationalSolution/uses.vue' /* webpackChunkName: "components/organizational-solution-uses" */).then(c => wrapFunctional(c.default || c))
export const RebrandingAccordian = () => import('../../components/rebranding/Accordian.vue' /* webpackChunkName: "components/rebranding-accordian" */).then(c => wrapFunctional(c.default || c))
export const RebrandingCatalogue = () => import('../../components/rebranding/Catalogue.vue' /* webpackChunkName: "components/rebranding-catalogue" */).then(c => wrapFunctional(c.default || c))
export const RebrandingContactForm = () => import('../../components/rebranding/ContactForm.vue' /* webpackChunkName: "components/rebranding-contact-form" */).then(c => wrapFunctional(c.default || c))
export const RebrandingContactFormInputs = () => import('../../components/rebranding/ContactFormInputs.vue' /* webpackChunkName: "components/rebranding-contact-form-inputs" */).then(c => wrapFunctional(c.default || c))
export const RebrandingCustomers = () => import('../../components/rebranding/Customers.vue' /* webpackChunkName: "components/rebranding-customers" */).then(c => wrapFunctional(c.default || c))
export const RebrandingCustomersLogos = () => import('../../components/rebranding/CustomersLogos.vue' /* webpackChunkName: "components/rebranding-customers-logos" */).then(c => wrapFunctional(c.default || c))
export const RebrandingEnterpriseServices = () => import('../../components/rebranding/EnterpriseServices.vue' /* webpackChunkName: "components/rebranding-enterprise-services" */).then(c => wrapFunctional(c.default || c))
export const RebrandingHero = () => import('../../components/rebranding/Hero.vue' /* webpackChunkName: "components/rebranding-hero" */).then(c => wrapFunctional(c.default || c))
export const RebrandingMaqueeLogo = () => import('../../components/rebranding/MaqueeLogo.vue' /* webpackChunkName: "components/rebranding-maquee-logo" */).then(c => wrapFunctional(c.default || c))
export const RebrandingMarqueeLogoSlider = () => import('../../components/rebranding/MarqueeLogoSlider.vue' /* webpackChunkName: "components/rebranding-marquee-logo-slider" */).then(c => wrapFunctional(c.default || c))
export const RebrandingProductsTabAccordian = () => import('../../components/rebranding/ProductsTabAccordian.vue' /* webpackChunkName: "components/rebranding-products-tab-accordian" */).then(c => wrapFunctional(c.default || c))
export const RebrandingProductsTabCard = () => import('../../components/rebranding/ProductsTabCard.vue' /* webpackChunkName: "components/rebranding-products-tab-card" */).then(c => wrapFunctional(c.default || c))
export const RebrandingServices = () => import('../../components/rebranding/Services.vue' /* webpackChunkName: "components/rebranding-services" */).then(c => wrapFunctional(c.default || c))
export const RebrandingStatistics = () => import('../../components/rebranding/Statistics.vue' /* webpackChunkName: "components/rebranding-statistics" */).then(c => wrapFunctional(c.default || c))
export const SecurityPossibility = () => import('../../components/security/possibility.vue' /* webpackChunkName: "components/security-possibility" */).then(c => wrapFunctional(c.default || c))
export const Solutions = () => import('../../components/solutions/index.vue' /* webpackChunkName: "components/solutions" */).then(c => wrapFunctional(c.default || c))
export const SolutionsQuote = () => import('../../components/solutions/quote.vue' /* webpackChunkName: "components/solutions-quote" */).then(c => wrapFunctional(c.default || c))
export const VirtualServerOsVr = () => import('../../components/virtual-server/osVr.vue' /* webpackChunkName: "components/virtual-server-os-vr" */).then(c => wrapFunctional(c.default || c))
export const VirtualServerPlansVirtual = () => import('../../components/virtual-server/plans-virtual.vue' /* webpackChunkName: "components/virtual-server-plans-virtual" */).then(c => wrapFunctional(c.default || c))
export const VirtualServerUsesVr = () => import('../../components/virtual-server/usesVr.vue' /* webpackChunkName: "components/virtual-server-uses-vr" */).then(c => wrapFunctional(c.default || c))
export const VpcBenefits = () => import('../../components/vpc/Benefits.vue' /* webpackChunkName: "components/vpc-benefits" */).then(c => wrapFunctional(c.default || c))
export const VpcDiffrence = () => import('../../components/vpc/Diffrence.vue' /* webpackChunkName: "components/vpc-diffrence" */).then(c => wrapFunctional(c.default || c))
export const VpcCalculator = () => import('../../components/vpc/calculator.vue' /* webpackChunkName: "components/vpc-calculator" */).then(c => wrapFunctional(c.default || c))
export const VpcCustomers = () => import('../../components/vpc/customers.vue' /* webpackChunkName: "components/vpc-customers" */).then(c => wrapFunctional(c.default || c))
export const VpcPossibility = () => import('../../components/vpc/possibility.vue' /* webpackChunkName: "components/vpc-possibility" */).then(c => wrapFunctional(c.default || c))
export const VpcUses = () => import('../../components/vpc/uses.vue' /* webpackChunkName: "components/vpc-uses" */).then(c => wrapFunctional(c.default || c))
export const VpsDiffrenceVPS = () => import('../../components/vps/DiffrenceVPS.vue' /* webpackChunkName: "components/vps-diffrence-v-p-s" */).then(c => wrapFunctional(c.default || c))
export const VpsBenefits = () => import('../../components/vps/benefits.vue' /* webpackChunkName: "components/vps-benefits" */).then(c => wrapFunctional(c.default || c))
export const VpsCalculator = () => import('../../components/vps/calculator.vue' /* webpackChunkName: "components/vps-calculator" */).then(c => wrapFunctional(c.default || c))
export const VpsCustomers = () => import('../../components/vps/customers.vue' /* webpackChunkName: "components/vps-customers" */).then(c => wrapFunctional(c.default || c))
export const VpsOs = () => import('../../components/vps/os.vue' /* webpackChunkName: "components/vps-os" */).then(c => wrapFunctional(c.default || c))
export const VpsPlans = () => import('../../components/vps/plans.vue' /* webpackChunkName: "components/vps-plans" */).then(c => wrapFunctional(c.default || c))
export const VpsUses = () => import('../../components/vps/uses.vue' /* webpackChunkName: "components/vps-uses" */).then(c => wrapFunctional(c.default || c))
export const RebrandingModalContactForm = () => import('../../components/rebranding/modal/ContactForm.vue' /* webpackChunkName: "components/rebranding-modal-contact-form" */).then(c => wrapFunctional(c.default || c))
export const RebrandingModalContactFormInputs = () => import('../../components/rebranding/modal/ContactFormInputs.vue' /* webpackChunkName: "components/rebranding-modal-contact-form-inputs" */).then(c => wrapFunctional(c.default || c))
export const RebrandingModal = () => import('../../components/rebranding/modal/Modal.vue' /* webpackChunkName: "components/rebranding-modal" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
