import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _2afa9cc2 = () => interopDefault(import('../pages/-ddos-protection/index.vue' /* webpackChunkName: "pages/-ddos-protection/index" */))
const _773eb1ee = () => interopDefault(import('../pages/-iran-windows-vps/index.vue' /* webpackChunkName: "pages/-iran-windows-vps/index" */))
const _7742c632 = () => interopDefault(import('../pages/-linux-server/index.vue' /* webpackChunkName: "pages/-linux-server/index" */))
const _62aa6b00 = () => interopDefault(import('../pages/-new/index.vue' /* webpackChunkName: "pages/-new/index" */))
const _9abeb7b0 = () => interopDefault(import('../pages/-organizational-solution/index.vue' /* webpackChunkName: "pages/-organizational-solution/index" */))
const _0a574c7a = () => interopDefault(import('../pages/-windows-server/index.vue' /* webpackChunkName: "pages/-windows-server/index" */))
const _13830b58 = () => interopDefault(import('../pages/aboutUs/index.vue' /* webpackChunkName: "pages/aboutUs/index" */))
const _6297c014 = () => interopDefault(import('../pages/cdn/index.vue' /* webpackChunkName: "pages/cdn/index" */))
const _3ac19976 = () => interopDefault(import('../pages/cloud-security/index.vue' /* webpackChunkName: "pages/cloud-security/index" */))
const _604b4564 = () => interopDefault(import('../pages/colocation/index.vue' /* webpackChunkName: "pages/colocation/index" */))
const _01bb72e7 = () => interopDefault(import('../pages/contactUs/index.vue' /* webpackChunkName: "pages/contactUs/index" */))
const _4326888e = () => interopDefault(import('../pages/dedicated-server/index.vue' /* webpackChunkName: "pages/dedicated-server/index" */))
const _1ac1cc1c = () => interopDefault(import('../pages/dns/index.vue' /* webpackChunkName: "pages/dns/index" */))
const _87e075ca = () => interopDefault(import('../pages/enterprise-solutions/index.vue' /* webpackChunkName: "pages/enterprise-solutions/index" */))
const _50ab1b3b = () => interopDefault(import('../pages/europe-vps/index.vue' /* webpackChunkName: "pages/europe-vps/index" */))
const _17bb2c6c = () => interopDefault(import('../pages/hourly-vps/index.vue' /* webpackChunkName: "pages/hourly-vps/index" */))
const _4540ea2f = () => interopDefault(import('../pages/iran-vps/index.vue' /* webpackChunkName: "pages/iran-vps/index" */))
const _4c12c6a4 = () => interopDefault(import('../pages/isolated-cloud/index.vue' /* webpackChunkName: "pages/isolated-cloud/index" */))
const _c4e013ae = () => interopDefault(import('../pages/linux-vps/index.vue' /* webpackChunkName: "pages/linux-vps/index" */))
const _42c73690 = () => interopDefault(import('../pages/manageServices/index.vue' /* webpackChunkName: "pages/manageServices/index" */))
const _e9f5ee18 = () => interopDefault(import('../pages/migrate/index.vue' /* webpackChunkName: "pages/migrate/index" */))
const _5cb5da10 = () => interopDefault(import('../pages/paas/index.vue' /* webpackChunkName: "pages/paas/index" */))
const _307821df = () => interopDefault(import('../pages/privateCloud/index.vue' /* webpackChunkName: "pages/privateCloud/index" */))
const _2296df0e = () => interopDefault(import('../pages/slider/index.vue' /* webpackChunkName: "pages/slider/index" */))
const _74ba8f72 = () => interopDefault(import('../pages/virtual-server/index.vue' /* webpackChunkName: "pages/virtual-server/index" */))
const _78edadb2 = () => interopDefault(import('../pages/vpc/index.vue' /* webpackChunkName: "pages/vpc/index" */))
const _718e4c7c = () => interopDefault(import('../pages/vps/index.vue' /* webpackChunkName: "pages/vps/index" */))
const _647bfdf8 = () => interopDefault(import('../pages/windows-vps/index.vue' /* webpackChunkName: "pages/windows-vps/index" */))
const _386191d1 = () => interopDefault(import('../pages/dedicated-server/-old/index.vue' /* webpackChunkName: "pages/dedicated-server/-old/index" */))
const _0f580cd0 = () => interopDefault(import('../pages/iran-vps/-old/index.vue' /* webpackChunkName: "pages/iran-vps/-old/index" */))
const _2dfb1658 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _340f129a = () => interopDefault(import('../pages/-meeting/_index.vue' /* webpackChunkName: "pages/-meeting/_index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/-ddos-protection",
    component: _2afa9cc2,
    name: "-ddos-protection"
  }, {
    path: "/-iran-windows-vps",
    component: _773eb1ee,
    name: "-iran-windows-vps"
  }, {
    path: "/-linux-server",
    component: _7742c632,
    name: "-linux-server"
  }, {
    path: "/-new",
    component: _62aa6b00,
    name: "-new"
  }, {
    path: "/-organizational-solution",
    component: _9abeb7b0,
    name: "-organizational-solution"
  }, {
    path: "/-windows-server",
    component: _0a574c7a,
    name: "-windows-server"
  }, {
    path: "/aboutUs",
    component: _13830b58,
    name: "aboutUs"
  }, {
    path: "/cdn",
    component: _6297c014,
    name: "cdn"
  }, {
    path: "/cloud-security",
    component: _3ac19976,
    name: "cloud-security"
  }, {
    path: "/colocation",
    component: _604b4564,
    name: "colocation"
  }, {
    path: "/contactUs",
    component: _01bb72e7,
    name: "contactUs"
  }, {
    path: "/dedicated-server",
    component: _4326888e,
    name: "dedicated-server"
  }, {
    path: "/dns",
    component: _1ac1cc1c,
    name: "dns"
  }, {
    path: "/enterprise-solutions",
    component: _87e075ca,
    name: "enterprise-solutions"
  }, {
    path: "/europe-vps",
    component: _50ab1b3b,
    name: "europe-vps"
  }, {
    path: "/hourly-vps",
    component: _17bb2c6c,
    name: "hourly-vps"
  }, {
    path: "/iran-vps",
    component: _4540ea2f,
    name: "iran-vps"
  }, {
    path: "/isolated-cloud",
    component: _4c12c6a4,
    name: "isolated-cloud"
  }, {
    path: "/linux-vps",
    component: _c4e013ae,
    name: "linux-vps"
  }, {
    path: "/manageServices",
    component: _42c73690,
    name: "manageServices"
  }, {
    path: "/migrate",
    component: _e9f5ee18,
    name: "migrate"
  }, {
    path: "/paas",
    component: _5cb5da10,
    name: "paas"
  }, {
    path: "/privateCloud",
    component: _307821df,
    name: "privateCloud"
  }, {
    path: "/slider",
    component: _2296df0e,
    name: "slider"
  }, {
    path: "/virtual-server",
    component: _74ba8f72,
    name: "virtual-server"
  }, {
    path: "/vpc",
    component: _78edadb2,
    name: "vpc"
  }, {
    path: "/vps",
    component: _718e4c7c,
    name: "vps"
  }, {
    path: "/windows-vps",
    component: _647bfdf8,
    name: "windows-vps"
  }, {
    path: "/dedicated-server/-old",
    component: _386191d1,
    name: "dedicated-server--old"
  }, {
    path: "/iran-vps/-old",
    component: _0f580cd0,
    name: "iran-vps--old"
  }, {
    path: "/",
    component: _2dfb1658,
    name: "index"
  }, {
    path: "/-meeting/:index",
    component: _340f129a,
    name: "-meeting"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
