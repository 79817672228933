
import Tooltip from "../Tooltip.vue";
export default {
  components: { Tooltip },
  props: [
    "title",
    "items",
    "classes",
    "styles",
    "col_styles",
    "col_classes",
    "icon",
  ],
};
